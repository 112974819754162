<div class="main-banner main-banner-with-form item-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>Keeping your Books True</h1>
                            <p>Creating one automation at a time!</p>
                            <div class="btn-box">
                                <a class="btn btn-primary" href="#pricing"> View Pricing</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-video">
                            <img style="height:300px; opacity:0%" src="assets/img/vb.png" alt="img">
                            <a href="https://www.youtube.com/watch?v=hktSzABdqLY" class="video-btn popup-youtube"><i class="icofont-ui-play"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-about></app-about>

<app-why-we-different></app-why-we-different>

<app-pricing></app-pricing>

<app-partner></app-partner>

<app-contact></app-contact>

<app-cta></app-cta>

<app-faq></app-faq>
