<section id="help" class="why-we-different ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>Highly Creative Solutions</span>
            <h2>How We Help You</h2>
        </div>
        <div class="tab">
            <ul class="tabs">
                <li><a href="#">
                    Consulting
                </a></li>
                <li><a href="#">
                    Data Engineering
                </a></li>
                <li><a href="#">
                    Accounting Reconstruction
                </a></li>
                <li><a href="#">
                    Tax Management
                </a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Financial & Crypto Consulting</h3>
                                <p>Let us get you on the right path to build you a better foundation</p>
                                <ul>
                                    <li>Executive Solutions</li>
                                    <li>Finance Strategy</li>
                                    <li>Decision Support</li>
                                    <li>Business & Merger Integration</li>
                                    <li>Business Process Improvement</li>
                                    <li>Crypto Management</li>
                                    <li>Crypto Education</li>
                                    <li>Crypto Integrations</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/Consult.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/BigData.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Getting the Most From Your Data</h3>
                                <p>Let us utilize your data to its fullest potential. Real-time charts and graphs could be at your fingertips.</p>
                                <ul>
                                    <li>Data Analytics</li>
                                    <li>Splunk</li>
                                    <li>Amazon Redshift</li>
                                    <li>Financial Data Organizing & Tagging</li>
                                    <li>System Development</li>
                                    <li>Data Integration</li>
                                    <li>Education</li>
                                    <li>Data Warehousing</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>A Strong Foundation Leads to More Growth</h3>
                                <p>We will rebuild your accounting department from the ground up with custom automations. This will save money and give you access to utilize your financial data to the fullest.

                                </p>
                                <ul>
                                    <li>Department Automation</li>
                                    <li>Save Money</li>
                                    <li>Tax Audits - Pass Guaranteed</li>
                                    <li>Clean Data</li>
                                    <li>Customized Accounting System</li>
                                    <li>Customized Reporting</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/Reconstruct.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/Tax.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Paying Taxes on Time and Keeping up with all Laws</h3>
                                <p>Let us help you stay compliant in federal and state taxes for your designated entity. We can also handle all employee related taxes.</p>
                                <ul>
                                    <li>Tax Accounting and Reporting</li>
                                    <li>Due Date Management </li>
                                    <li>Risk, Strategy and Operations</li>
                                    <li>Compliance Process Automation</li>
                                    <li>Tax Data Analysis</li>
                                    <li>Tax Audit Management</li>
                                    <li>Tax forecasting</li>
                                    <li>Deferred Tax Management</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>