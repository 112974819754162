<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Trusted Clients</span>
            <h2></h2>
        </div>
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#">
                    <img style="height: 140px" src="assets/img/WookLogoClear.svg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img style="height: 140px" src="assets/img/teeboxcaddieFinal.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img style="height: 140px" src="assets/img/Spera.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img style="height: 140px" src="assets/img/Aries.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img style="height: 140px" src="assets/img/h2o3.png" alt="img">
                </a>
            </div>
        </div>
    </div>
</section>