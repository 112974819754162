<section id="pricing" class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Latest and Best</span>
            <h2>Plans</h2>
            <p>All Plans will have a Finalized Price After Consultation</p>
        </div>
        <div class="tab-slider-nav">
            <ul class="tab-slider-tabs">
                <li class="tab-slider-trigger active" rel="consulting">Consulting</li>
                <li class="tab-slider-trigger" rel="monthly">Monthly</li>
            </ul>
        </div>
        <div class="tab-slider-container">
            <div id="consulting" class="tab-slider-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                1-HR General
                            </div>
                            <div class="price">
                                <div class="price">
                                    <sup>$</sup>250.00
                                </div>
                            </div>
                            <ul class="pricing-features">
                                <li>General Accounting Questions</li>
                                <li>Business Process Improvement</li>
                                <li>Business & Merger Integration</li>
                                <li>Data Integration & Warehousing</li>
                                <li>Discuss Tax Situations</li>
                                <li>Tax Forecasting</li>
                                <li>Decision Support</li>
                            </ul>
                            <a href="#" class="btn" style="background-color: #4F4F51;">Let's Talk</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                1-HR Crypto
                            </div>
                            <div class="price">
                                <sup>$</sup>500.00
                            </div>
                            <ul class="pricing-features">
                                <li>Crypto Education</li>
                                <li>Discuss Crypto Management</li>
                                <li>Discuss Crypto Integrations</li>
                                <li>Discuss Blockchain Technologies</li>
                                <li>Auditing Blockchain Address</li>
                                <li>Crypto Tax Support</li>
                                <li>Decision Support</li>
                            </ul>
                            <a href="#" class="btn" style="background-color: #4F4F51;">Let's Talk</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                New Project | Accounting Reconstruction
                            </div>
                            <div class="price">
                                Let's Talk
                            </div>
                            <ul class="pricing-features">
                                <li>Department Automation</li>
                                <li>Customized Accounting Systems</li>
                                <li>Customized Reporting</li>
                                <li>Data Engineering</li>
                                <li>Tax Preparation & Audit</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#" class="btn" style="background-color: #4F4F51;">Let's Talk</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="monthly" class="tab-slider-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Early
                            </div>
                            <div class="price">
                                <sup>$</sup>250.00
                            </div>
                            <ul class="pricing-features">
                                <li>Create Accounting Process</li>
                                <li>Maintain Accounting Process</li>
                                <li>Inventory Management</li>
                                <li>Reconciliation of Accounts</li>
                                <li>Monthly & Yearly Closing</li>
                                <li>Monthly & Yearly Closing</li>
                                <li>Creation of Financial Statements</li>
                                <li>Weekday Support</li>
                            </ul>
                            <a href="#" class="btn" style="background-color: #4F4F51;">Let's Talk</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Growing
                            </div>
                            <div class="price">
                                <sup>$</sup>500.00
                            </div>
                            <ul class="pricing-features">
                                <li>Create Accounting Process</li>
                                <li>Maintain Accounting Process</li>
                                <li>Inventory Management</li>
                                <li>Reconciliation of Accounts</li>
                                <li>Monthly & Yearly Closing</li>
                                <li>Monthly & Yearly Closing</li>
                                <li>Creation of Financial Statements</li>
                                <li>Asset Management</li>
                                <li>Weekday Support</li>
                            </ul>
                            <a href="#" class="btn" style="background-color: #4F4F51;">Let's Talk</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Established
                            </div>
                            <div class="price">
                                Let's Talk
                            </div>
                            <ul class="pricing-features">
                                <li>Create Accounting Process</li>
                                <li>Maintain Accounting Process</li>
                                <li>Inventory Management</li>
                                <li>Reconciliation of Accounts</li>
                                <li>Monthly & Yearly Closing</li>
                                <li>Monthly & Yearly Closing</li>
                                <li>Creation of Financial Statements</li>
                                <li>Asset Management</li>
                                <li>Tax Management</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#" class="btn" style="background-color: #4F4F51;">Let's Talk</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>