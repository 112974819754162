<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/Asset5.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/Asset6.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/Asset7.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/Asset8.png" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About VeraBooks</span>
                    <h2>Our goal is to Automate Accounting, so we can reduce errors and keep books true by </h2>
                    <ul>
                        <li>Planning</li>
                        <li>Audit</li>
                        <li>Optimization</li>
                        <li>Innovation</li>
                        <li>Data Analytics</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>