<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img style="height: 50px" src="assets/img/Vera-Books-Teal.png" alt="logo">
            <img style="height: 50px" src="assets/img/VB-Teal.png"  alt="logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">About Us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('help')">How We Help</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Plans</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact Us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('faq')">Faq</span></li>
            </ul>
        </div>
    </div>
</nav>