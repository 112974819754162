<section class="cta-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <div class="cta-content">
                    <h3>VeraBooks</h3>
                    <span>Let us help you get the most out of your financial data</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 text-right">
                <a href="tel:12104194674" class="btn btn-primary">Start Consultation</a>
            </div>
        </div>
    </div>
</section>