<section id="team" class="team-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>Meet Our Expert</span>
            <h2>Our Creative <b>Team</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="team-slides owl-carousel owl-theme">
            <div class="team-box">
                <img src="assets/img/team-img1.jpg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Steven Smitn</h3>
                        <span class="post">Web Developer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/team-img2.jpg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Alina Eva</h3>
                        <span class="post">UI/UX Designer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/team-img3.jpg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">James Anderson</h3>
                        <span class="post">Photographer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/team-img4.jpg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Eva Lucy</h3>
                        <span class="post">Web Designer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>