<section id="services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>What We Do</span>
            <h2>Our <b>Services</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img1.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-headphone-alt-3"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Web Design</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img2.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Branding Design</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img3.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Digital Marketing</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img4.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-tools"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>App Development</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img5.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-settings-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Easy Customization</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img6.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Fully Responsive</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>