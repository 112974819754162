<footer class="footer-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <p><i class="icofont-copyright"></i>2022 VeraBooks <a href="https://speratechnologies.io" target="_blank">All Rights Reserved By Spera Technologies</a></p>
            </div>
            <div class="col-lg-6 col-md-5">
                <ul>
                    <li><a href="https://twitter.com/verabooksapp" target="_blank"><i class="icofont-twitter"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/vera-books/" target="_blank"><i class="icofont-linkedin"></i></a></li>-
                </ul>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="icofont-swoosh-up"></i></div>